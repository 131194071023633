<template>
<div id="footer">
  <!-- Links to stastudent.no. Could get more content. -->
  <p></p>
  <p><a href="https://stastudent.no" target="_blank">Sjekk ut stastudent.no her</a></p>
  <p><a href="https://valg.usit.no/valg/login.do" target="_blank">Stem her</a></p>
  <!-- <div style="color:blue; cursor:pointer " @click="admin"><u>Admin</u></div> -->

</div>
</template>

<script>
import {page} from "../sharedVars";
export default {
  name: "Footer",
  methods: {
    admin(){
      page.set('reset');
      this.$router.push('/newcand').catch(() => {});
    }
  }

}
</script>

<style scoped>

</style>