import Vue from 'vue'
import App from './App'
import router from './router'
import VueMeta from 'vue-meta'
import {sharedVars} from "./sharedVars";

Vue.config.productionTip = false;
Vue.use(VueMeta);
//Standard Value

new Vue({
  router, //Include the router
  VueMeta,
  beforeCreate() {
    router.beforeEach((to, from, next) => {
      if (to.meta.requiresAuth) {
        if (!sharedVars.admin) next('/password')
        else next()
      } else next()
    })
  },
  mounted()
  {
    window.addEventListener("load", this.refresh);
    window.addEventListener("popstate", this.pop); //Would prefer to have a state before new page is loaded.
    //Event listener to call the method "refresh" on load
  },
  beforeDestroy () {
    window.removeEventListener("load", this.refresh);
    window.removeEventListener("popstate", this.pop);
  },
  methods:
    {
    refresh() {
      const path = '/'; //Define where we want the page to load
      if (this.$route.path !== path && this.$route.path !== "/newcand" && this.$route.path !== "/newcand/")
        this.$router.push({path: path});
        //Force a new entry to land on the main page. Throws out if you try to load
        // directly to a question. Exception to this is when the quiz is already loaded.
    },
      pop(){ //We override the back and forward functions on the page.
          router.go();
      }
  },
  render: h => h(App), //Renders 'App' as main component
})
    .$mount('#app');