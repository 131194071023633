import Vue from "vue";

//Make an object for candidate
function Candidate(id, name, fac, picture, q1, q2, q3, q4, q5, q6, q7, q8, q9, q10, stillerTil)
    {
        this.ID = id;
        this.Name = name;
        this.Fac = fac;
        this.Picture = picture;
        this.Q1 = q1;
        this.Q2 = q2;
        this.Q3 = q3;
        this.Q4 = q4;
        this.Q5 = q5;
        this.Q6 = q6;
        this.Q7 = q7;
        this.Q8 = q8;
        this.Q9 = q9;
        this.Q10 = q10;
        this.StillerTil = stillerTil;
        return this;
    }

    //Export our candidates array to be a variable that Vue can work with.
export let cand = Vue.observable({
    candidates: []
});
/*
export function candPop(id){
            cand.candidates[id].pop();

    }
*/
/*
    Forklaring:

    Fac:
    (1) Humaniora og Pedagogikk,
    (2) Teknologi og Realfag,
    (3) Handelshøyskolen,
    (4) Lærerutdanningen,
    (5) Kunstfag,
    (6) Samfunnnsvitenskap,
    (7) Helse og Idrett

    Spørsmålssvar:
    1 Helt Enig
    2 Delvis Enig
    3 Nøytral
    4 Delvis Uenig
    5 Helt Uenig
    6 Vet ikke

    Spørsmål:
    Q1	Studenter bør kunne få stille til rektor ved UiA.
    Q2	Når korona har gitt seg bør vi fortsette med mer digital undervisning enn før.
    Q3	UiA bør heise regnbueflagget fra hovedflaggstanga.
    Q4	UiA bør ha en kjøttfri dag i kantina hver uke.
    Q5	Universitetet bør ha en tiltaksplan mot mobbing.
    Q6	Tilrettelegging bør evalueres i større grad.
    Q7	Agder har et behov for et større fokus på mangfold.
    Q8	Jeg synes alkohol er en sentral del av fadderuka.
    Q9	Alle undervisere bør ha fokus på studentaktiv undervisning.
    Q10	Alle studenter bør få automatisk begrunnelse for eksamen.


    Stiller til:
    1 Både Fakultetsstyret og SP
    2 Kun SP
    3 Kun Fakultetsstyret


    Eksempel:

cand.candidates.push( new Candidate(0, "Thor Eivind Forberg", 1, 'ThorEivind.jpg',4,5,
    2,4,2,4,2,4,4,1,2,5,2,3));

cand.candidates.push( new Candidate(36, "Kaia Pihl",5,null,null,null,
    null,null,null,null,null,null,null,null,null,null,null,3));
*/

cand.candidates.push(new Candidate(0,"Test",8,null,null,null,null,null,null,null,null,null,null,null,null))
cand.candidates.push(new Candidate(1, "Andre Elisè Brunton Jacksonville", 4, null, null, null, null, null, null, null, null, null, null, null, 1));
cand.candidates.push(new Candidate(2, "Christoffer Olsen", 4, 'Christoffer Olsen .jpg', 4, 2, 1, 2, 3, 2, 1, 1, 3, 5, 3));
cand.candidates.push(new Candidate(3, "Guro Krogh Hagen", 4, 'Guro.jpg', 4, 1, 4, 3, 5, 1, 1, 1, 3, 4, 2));
cand.candidates.push(new Candidate(4, "Kjell Arne Lønningen", 4, null, null, null, null, null, null, null, null, null, null, null, 2));
cand.candidates.push(new Candidate(5, "Knut Benjamin Jacobsen", 4, 'Knut Benjamin.jpg', 4, 5, 4, 3, 4, 4, 2, 3, 2, 4, 1));
cand.candidates.push(new Candidate(6, "Kristoffer Fuglerud Ask", 4, 'Kristoffer Fuglerud Ask.jpg', 2, 4, 1, 4, 1, 2, 2, 2, 3, 4, 1));
cand.candidates.push(new Candidate(7, "Ragnhild Danielsen Lybekk", 4, 'ragnhild.jpg', 1, 3, 2, 2, 4, 2, 3, 2, 3, 4, 3));
cand.candidates.push(new Candidate(8, "Rufo Gerard Tulabing Garcia", 4, 'Rufo.jpg', 4, 2, 2, 2, 5, 2, 1, 3, 2, 4, 1));
cand.candidates.push(new Candidate(9, "Amalie Porsbøll", 3, 'Amalie.jpg', 2, 2, 4, 2, 3, 2, 2, 2, 3, 5, 1));
cand.candidates.push(new Candidate(10, "Beinhur H. Weldegiorgis", 3, null, null, null, null, null, null, null, null, null, null, null, 2));
cand.candidates.push(new Candidate(11, "Bjørn Undheim Serigstad", 3, 'Bjorn Undheim Serigstad.jpg', 1, 1, 4, 5, 2, 1, 2, 5, 2, 5, 2));
cand.candidates.push(new Candidate(12, "Daniils Nikolajevs", 3, 'Daniils Nikolajevs.jpg', 2, 3, 2, 3, 4, 2, 1, 2, 2, 3, 2));
cand.candidates.push(new Candidate(13, "Eva Scharpf", 3, 'Eva Scharpf.png', 1, 1, 2, 3, 4, 2, 1, 1, 1, 2, 2));
cand.candidates.push(new Candidate(14, "Herman Kure", 3, 'Herman Kure.jpg', 1, 3, 2, 2, 3, 2, 3, 3, 2, 5, 1));
cand.candidates.push(new Candidate(15, "Julie Helland", 3, 'Julie Helland.png', 2, 2, 4, 2, 4, 2, 2, 2, 3, 5, 1));
cand.candidates.push(new Candidate(16, "Kristoffer Spenik", 3, 'Kristoffer.jpg', 2, 3, 2, 3, 5, 3, 1, 1, 2, 2, 3));
cand.candidates.push(new Candidate(17, "Oda Aamelfot Krohn", 3, 'Oda.jpg', 2, 1, 3, 1, 4, 2, 2, 1, 1, 4, 2));
cand.candidates.push(new Candidate(18, "Thomas G. Abrahamsen", 3, 'Thomas G Abrahamsen.jpeg', 4, 4, 2, 5, 5, 4, 2, 2, 3, 4, 1));
cand.candidates.push(new Candidate(20, "Ida Mørkesdal", 7, 'Ida M.jpg', 1, 3, 4, 2, 5, 2, 2, 1, 3, 5, 3));
cand.candidates.push(new Candidate(21, "Line Gjelstad", 7, 'Line Gjelstad.jpg', 2, 1, 1, 2, 4, 1, 2, 2, 1, 5, 1));
cand.candidates.push(new Candidate(22, "Marius Traaholt", 7, 'Marius.png', 2, 1, 1, 1, 4, 2, 2, 2, 3, 4, 3));
cand.candidates.push(new Candidate(23, "Morgan Lohne", 7, null, null, null, null, null, null, null, null, null, null, null, 3));
cand.candidates.push(new Candidate(24, "Stine Rambekk Henriksen", 7, 'Stine.jpg', 4, 3, 2, 1, 4, 3, 2, 1, 2, 4, 3));
cand.candidates.push(new Candidate(25, "Vegard Nordstokke Valen", 7, 'Vegard Nordstokke.jpg', 1, 4, 3, 2, 1, 3, 2, 1, 1, 2, 3));
cand.candidates.push(new Candidate(26, "Christina Maria Konz", 1, 'Christina Maria.jpg', 2, 4, 2, 1, 2, 2, 2, 1, 1, 4, 2));
cand.candidates.push(new Candidate(27, "Ina Frøhne", 1, 'Ina.jpg', 1, 3, 4, 2, 5, 2, 4, 1, 1, 2, 1));
cand.candidates.push(new Candidate(28, "Kevin M. Kirkhus", 1, 'Kevin.jpg', 2, 3, 1, 3, 1, 1, 2, 2, 3, 5, 1));
cand.candidates.push(new Candidate(29, "Kristine Norevik O´Neil", 1, 'Kristine.jpg', 2, 2, 1, 2, 5, 2, 2, 3, 3, 4, 3));
cand.candidates.push(new Candidate(30, "Malene Vatnøy", 1, 'Malene.jpg', 1, 1, 2, 2, 5, 2, 1, 2, 1, 2, 1));
cand.candidates.push(new Candidate(31, "Martin Jonathan Pizarro Røiseland", 1, null, null, null, null, null, null, null, null, null, null, null, 2));
cand.candidates.push(new Candidate(32, "Thor Eivind Forberg", 1, 'ThorEivind.jpg', 4, 2, 3, 1, 5, 4, 2, 2, 4, 4, 3));
cand.candidates.push(new Candidate(33, "William Moe Bjørnsen", 1, 'William .png', 4, 4, 4, 2, 4, 4, 2, 3, 4, 5, 3));
cand.candidates.push(new Candidate(34, "Kaia Pihl", 5, null, null, null, null, null, null, null, null, null, null, null, 3));
cand.candidates.push(new Candidate(35, "Ole Henrik Bottolf", 5, null, null, null, null, null, null, null, null, null, null, null, 3));
cand.candidates.push(new Candidate(36, "Simen Vad", 5, 'Simen.jpg', 4, 3, 4, 3, 2, 2, 3, 3, 1, 4, 3));
cand.candidates.push(new Candidate(37, "Stine Regina Tusvik", 5, null, null, null, null, null, null, null, null, null, null, null, 1));
cand.candidates.push(new Candidate(38, "Emil Gudelis", 6, 'Emil Gudelis.jpg', 3, 1, 1, 1, 4, 1, 2, 1, 1, 4, 1));
cand.candidates.push(new Candidate(40, "Magnus Ellefsen Moen", 6, 'Magnus Ellefsen Moen.jpeg', 2, 2, 3, 1, 4, 2, 2, 1, 2, 4, 2));
cand.candidates.push(new Candidate(41, "Marie Nørvåg", 6, 'Marie Nørvåg.jpg', 2, 1, 1, 1, 5, 2, 2, 1, 1, 2, 3));
cand.candidates.push(new Candidate(42, "Signe Lefdal Hove", 6, null, 3, 1, 3, 1, 4, 1, 2, 3, 2, 4, 2));
cand.candidates.push(new Candidate(43, "Wenche Kverneland", 6, 'Wenche Kverneland.jpg', 2, 1, 3, 1, 4, 1, 2, 1, 2, 1, 2));
cand.candidates.push(new Candidate(44, "Espen Herredsvela", 2, 'Espen Herredsvela.jpg', 2, 3, 2, 4, 3, 3, 5, 1, 4, 2, 1));
cand.candidates.push(new Candidate(45, "Mohamed Ali Mohamad", 2, 'Mohamad.png', 2, 5, 2, 2, 4, 1, 1, 1, 2, 4, 2));
cand.candidates.push(new Candidate(46, "Ole Martin Mørk", 2, null, 2, 3, 3, 2, 5, 4, 2, 1, 1, 5, 3));
cand.candidates.push(new Candidate(47, "Sunniva Sebdal Knudsen", 2, null, null, null, null, null, null, null, null, null, null, null, 1));
cand.candidates.push(new Candidate(48, "Zahir Mohamed", 2, 'Zahir.jpg', 2, 3, 1, 3, 5, 4, 1, 2, 1, 5, 3));
