<template>
<div>
  <!-- This is the main landing page. The text here could be collapsable, but chose not to. -->
  <h3>Trykk start for å starte.</h3>
  <div class="landingbox">
    <p>Dette er STA sin valgomat for studentvalget 2023.</p>
    <p>
      Valgomaten er ment som en pekepinn, men ikke en fasit, på hvilke av dine potensielle representanter som du er mest enig
      med blant de som stiller til valg. På slutten av valgomaten kan du sammenligne dine egne svar med kandidatenes
      svar. Du får bare opp kandidater ved ditt eget fakultet/avdeling.
    </p>
    <p>
      Poeng er gitt ved at ditt svar er sammenlignet opp mot kandidatens, og det gis to poeng ved likt svar (men bare ett poeng der
      begge har svart "Nøytral"). I tillegg gis det ett poeng ved nærliggende svar, altså svar som står plassert
      rett ved siden av hverandre. Deretter gis det ingen poeng ved svar som er to steg fra hverandre, og ett minuspoeng
      ved svar som er tre eller flere steg fra hverandre, altså der dere er helt uenige.
    </p>
    <p>
      <i>Eksempel: Hvis du svarer "Helt enig" på et spørsmål og kandidaten har svart "Litt Uenig" eller "Helt Uenig" på det
      samme spørsmålet, vil denne kandidaten få ett minuspoeng.</i>
    </p>
    <p>
      Alle kandidater ved ditt fakultet/avdeling er med i denne valgomaten, men dessverre har ikke alle kandidatene avgitt
      svar. Du kan ikke få poeng på disse kandidatene, fordi vi ikke har sammenlingningsgrunnlag, og du vil se "Ingen svar"
      ved sammenlinging.
    </p>
  </div>
</div>
</template>


<script>
export default {
  name: "Landing"
}
</script>

<style scoped>

</style>