import Vue from "vue"
import Router from 'vue-router'
import Landing from "./components/Q/Landing";


Vue.use(Router); //Critical for navigation on the page.

export default new Router
({
    routes:
   [
       {
           path: "/",
           name: "Landing",
           component: Landing,
       // All subsequent routes are standard navigation to question.
       // Make sure to include here for new questions.
       },
           {

                path: "/0",
                name: 'SpmHome',
                component: ()=>
                    import("./components/Q/Faculty")
           },
           {
                path: '/1',
                name: 'spm1',
                component: ()=>
                    import("./components/Q/Spm1"),
           },
           {
                path: '/2',
                name: 'spm2',
                component: ()=>
                    import("./components/Q/Spm2"),
           },
           {
               path: '/3',
               name: 'spm3',
               component: ()=>
                   import("./components/Q/Spm3"),
           },
           {
               path: '/4',
               name: 'spm4',
               component: ()=>
                   import("./components/Q/Spm4"),
           },
           {
               path: '/5',
               name: 'spm5',
               component: ()=>
                   import("./components/Q/Spm5"),
           },
           {
               path: '/6',
               name: 'spm6',
               component: ()=>
                   import("./components/Q/Spm6"),
           },
           {
               path: '/7',
               name: 'spm7',
               component: ()=>
                   import("./components/Q/Spm7"),
           },
           {
               path: '/8',
               name: 'spm8',
               component: ()=>
                   import("./components/Q/Spm8"),
           },
           {
               path: '/9',
               name: 'spm9',
               component: ()=>
                   import("./components/Q/Spm9"),
           },
           {
               path: '/10',
               name: 'spm10',
               component: ()=>
                   import("./components/Q/Spm10"),
           },
           {
               path: '/11',
               name: 'spm11',
               component: ()=>
                   import("./components/Q/Spm11"),
           },
           {
               path: '/12',
               name: 'spm12',
               component: ()=>
                   import("./components/Q/Spm12"),
           },
           {
               path: '/13',
               name: 'spm13',
               component: ()=>
                   import("./components/Q/Spm13"),
           },//New question-page goes after here.
           {
               path: "/result",
               name: 'result',
               component: ()=>
                   import("./components/Q/Result")
           },
    //]},
       {
           path: "/newcand",
           name: 'newCand',
           component: () =>
               import("./components/NewCand"),
           meta: {
               requiresAuth: true
           },
           children: [
               {

                   path: "/0",
                   name: 'SpmHomeAdmin',
                   component: ()=>
                       import("./components/Q/Faculty")
               },

               {
                   path: '/1',
                   name: 'spm1Admin',
                   component: ()=>
                       import("./components/Q/Spm1"),
               },
               {
                   path: '/2',
                   name: 'spm2Admin',
                   component: ()=>
                       import("./components/Q/Spm2"),
               },
               {
                   path: '/3',
                   name: 'spm3Admin',
                   component: ()=>
                       import("./components/Q/Spm3"),
               },
               {
                   path: '/4',
                   name: 'spm4Admin',
                   component: ()=>
                       import("./components/Q/Spm4"),
               },
               {
                   path: '/5',
                   name: 'spm5Admin',
                   component: ()=>
                       import("./components/Q/Spm5"),
               },
               {
                   path: '/6',
                   name: 'spm6Admin',
                   component: ()=>
                       import("./components/Q/Spm6"),
               },
               {
                   path: '/7',
                   name: 'spm7Admin',
                   component: ()=>
                       import("./components/Q/Spm7"),
               },
               {
                   path: '/8',
                   name: 'spm8Admin',
                   component: ()=>
                       import("./components/Q/Spm8"),
               },
               {
                   path: '/9',
                   name: 'spm9Admin',
                   component: ()=>
                       import("./components/Q/Spm9"),
               },
               {
                   path: '/10',
                   name: 'spm10Admin',
                   component: ()=>
                       import("./components/Q/Spm10"),
               },
               {
                   path: '/11',
                   name: 'spm11Admin',
                   component: ()=>
                       import("./components/Q/Spm11"),
               },
               {
                   path: '/12',
                   name: 'spm12Admin',
                   component: ()=>
                       import("./components/Q/Spm12"),
               },
               {
                   path: '/13',
                   name: 'spm13Admin',
                   component: ()=>
                       import("./components/Q/Spm13"),
               },//New question-page goes after here.
           ]
       },
       {
           path:"/password",
           name:"password",
           component: () =>
               import("./components/Protected")
       }
   ],
})