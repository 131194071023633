<template>
  <div class="button-view">
    <button id="opt1" v-if="btn1=== false" class="options" v-on:click="OnClick(1)">Helt Enig</button> <!-- Option 1 -->
    <button  v-if="btn1=== true" class="optionsAlt" v-on:click="OnClick(1)">Helt Enig</button> <!-- Option 1 Alt -->

    <button id="opt2" v-if="btn2=== false" class="options" v-on:click="OnClick(2)">Delvis Enig</button> <!-- Option 2 -->
    <button  v-if="btn2=== true" class="optionsAlt" v-on:click="OnClick(2)">Delvis Enig</button> <!-- Option 2 Alt-->

    <button id="opt3" v-if="btn3=== false" class="options" v-on:click="OnClick(3)">Nøytral</button> <!-- Option 3 -->
    <button  v-if="btn3=== true" class="optionsAlt" v-on:click="OnClick(3)">Nøytral</button> <!-- Option 3 Alt-->

    <button id="opt4" v-if="btn4=== false" class="options" v-on:click="OnClick(4) ">Delvis Uenig</button> <!-- Option 4 -->
    <button id="opt4alt" v-if="btn4=== true" class="optionsAlt" v-on:click="OnClick(4) ">Delvis Uenig</button> <!-- Option 4 Alt -->

    <button id="opt5" v-if="btn5=== false"  class="options" v-on:click="OnClick(5)">Helt Uenig</button> <!-- Option 5 -->
    <button id="opt5alt" v-if="btn5=== true"  class="optionsAlt" v-on:click="OnClick(5)">Helt Uenig</button> <!-- Option 5 Alt -->
    <!--
    <button id="opt6" v-if="btn6=== false"  class="options" v-on:click="OnClick(6)">Vet Ikke</button> Option 6
    <button id="opt6alt" v-if="btn6=== true"  class="optionsAlt" v-on:click="OnClick(6)">Vet Ikke</button>  Option 6 Alt -->
  </div>
</template>

<script>
import {questions, page} from "@/sharedVars";

export default {
  name: "Buttons",
  data(){
    return{
      btn1: false,
      btn2: false,
      btn3: false,
      btn4: false,
      btn5: false,
      btn6: false,
    }
  },
  methods:
    {
    OnClick(option) {
      //Get which question we're at, and set an appropriate response. Could be done by getting page aswell,
      // but this works. Was done early in development.
      questions.set(page.get(), option);
      //console.log("Spørsmål " + page.get() + ": " + option); //For testing

      switch (option){
        case 1:
          this.btn1 = true;
          this.btn2 = false;
          this.btn3 = false;
          this.btn4 = false;
          this.btn5 = false;
          this.btn6 = false;
          break;
        case 2:
          this.btn1 = false;
          this.btn2 = true;
          this.btn3 = false;
          this.btn4 = false;
          this.btn5 = false;
          this.btn6 = false;
          break;
        case 3:
          this.btn1 = false;
          this.btn2 = false;
          this.btn3 = true;
          this.btn4 = false;
          this.btn5 = false;
          this.btn6 = false;
          break;
        case 4:
          this.btn1 = false;
          this.btn2 = false;
          this.btn3 = false;
          this.btn4 = true;
          this.btn5 = false;
          this.btn6 = false;
          break;
        case 5:
          this.btn1 = false;
          this.btn2 = false;
          this.btn3 = false;
          this.btn4 = false;
          this.btn5 = true;
          this.btn6 = false;
          break;
        case 6:
          this.btn1 = false;
          this.btn2 = false;
          this.btn3 = false;
          this.btn4 = false;
          this.btn5 = false;
          this.btn6 = true;
          break;
      }


    },
  },
}
</script>

<style scoped>


</style>