
import {cand} from './candidates'
import {userAns, points, sharedVars} from "./sharedVars";


export function calcOnClick(questionId, questionAns)
{
    if(questionId === 0) //Do nothing if we're on the question for faculty.
    {
        return true;
    }
    userAns[questionId] = questionAns;

    //console.log("CalcOnClick:" +  questionId + " Ans: " + questionAns); //Testing
    const fac = sharedVars.fakultet;
    if(questionId === 10)
    {
        for(const i in cand.candidates)
        {

             if (fac === cand.candidates[i].Fac)
             {
                 points[i] = 0;
                //console.log("Match with: " + candidates[i].Name) //Testing
                try {
                    for(let ans in userAns)
                    points[i] = points[i] + compare(i, ans, userAns[ans]); //Call function "compare" and add points to that users score.
                    //console.log(cand.candidates[i].Name + ": " + points[i]); //Testing
                } catch (e) {
                    console.log(e)
                }
            }
        }
    }
}

function compare(x, questionId, questionAns)
{
    //console.log(x + " " + questionId + " " + questionAns); //Testing
    let cAns = null, num = 0;
    if(cand.candidates[x].Q1 === null && cand.candidates[x].Q6 === null )
    {
        return 0;
    }
    switch(questionId)
    {
        case "1"||1:
            cAns = cand.candidates[x].Q1;
            break;
        case "2"||2:
            cAns = cand.candidates[x].Q2;
            break;
        case "3"||3:
            cAns = cand.candidates[x].Q3;
            break;
        case "4"||4:
            cAns = cand.candidates[x].Q4;
            break;
        case "5"||5:
            cAns = cand.candidates[x].Q5;
            break;
        case "6"||6:
            cAns = cand.candidates[x].Q6;
            break;
        case "7"||7:
            cAns = cand.candidates[x].Q7;
            break;
        case "8"||8:
            cAns = cand.candidates[x].Q8;
            break;
        case "9"||9:
            cAns = cand.candidates[x].Q9;
            break;
        case "10"||10:
            cAns = cand.candidates[x].Q10;
            break;
        /*case "11"||11:
            cAns = cand.candidates[x].Q11;
            break;
        case "12"||12:
            cAns = cand.candidates[x].Q12;
            break;
        case "13"||13:
            cAns = cand.candidates[x].Q13;
            break;
        */
        default:
            break;

    }

    //console.log("cAns: " + cAns); //Testing

    if(questionAns === 6 || cAns === 6){
        num = 0;
    }
    else if (questionAns === cAns){
        if(questionAns === 3){ //If both parties answer neutral, we only give 1 point.
            num = 1
        }
        else //Else, on the same answer we give two points.
        {
            num = 2;
        }
    }
    else if(questionAns === cAns+1 || questionAns === cAns-1)
    {
        num = 1;
    }
    else if(questionAns === cAns+2 || questionAns === cAns-2)
    {
        num = 0;
    }
    else if(questionAns === cAns+3 || questionAns === cAns-3 || questionAns === cAns+4 || questionAns === cAns-4)
    {
        num = -1;
    }

    //console.log("Num: " + num); //Testing

return num;

}
