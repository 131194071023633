import Vue from "vue";

let question = new Array(11).fill(null); //Length of array should be equal to number of questions + 1 (For the question about faculty).

export const sharedVars = Vue.observable({
    cont: true,
    pagecount: -1,
    fakultet: 0,
    numOfPages: question.length,
    admin: false
});

export const page = {
    set(number){
        if(number === 'reset'){ //Sets page to -1 (Landing-page)
            sharedVars.pagecount = -1;
        }
        if(number == -1){ //Dont worry about type-matching. Sets page to 1 less than before
            sharedVars.pagecount = sharedVars.pagecount-1;
        }
        else if(number == 1){ //Dont worry about type-matching. Sets page to 1 more than before
            sharedVars.pagecount = sharedVars.pagecount+1;
        }
        else if(number == 0){ //Dont worry about type-matching. Sets page to 0 (The faculty-page)
            sharedVars.pagecount = 0;
        }
    },
    get(){
        return sharedVars.pagecount; //Get which page we're at.
    }
}


export const adminChange = {
    set(){
        sharedVars.admin = !sharedVars.admin;
    },
    get(){
        return sharedVars.admin;
    }
}

export const questions = {
    set(number, option){
        if(!isNaN(number) )
        question[number] = option;
        /* //This prints the full array. Useful for testing
        for(let i in question){
            console.log(i + ": " + question[i])
        }*/
        if(number === 0)
        {
            if(option=== 1){
                //console.log("Fakultet: HumPed"); //Testing
                sharedVars.fakultet = 1;
            }
            else if(option === 2){
                //console.log("Fakultet: TekReal") //Testing
                sharedVars.fakultet = 2;
            }
            else if(option === 3){
                //console.log("Fakultet: Handel") //Testing
                sharedVars.fakultet = 3;
            }
            else if(option === 4) {
                //console.log("Fakultet: Lærer") //Testing
                sharedVars.fakultet = 4;
            }
            else if(option === 5) {
                //console.log("Fakultet: Kunst") //Testing
                sharedVars.fakultet = 5;
            }
            else if(option === 6){
                //console.log("Fakultet: Samfunn") //Testing
                sharedVars.fakultet = 6;
            }
            else if(option === 7){
                //console.log("Fakultet: Helse") //Testing
                sharedVars.fakultet = 7;
            }

        }
    },
    get(num) {
        return question[num];
    },
    getFull(){
        return question;
    }
}

export let points = new Array(60).fill(0); //The Length of this one, should be higher than the total
// number of candidates. Might work without aswell. Not sure tbh.
export let userAns = [];  //Export the users answers.
