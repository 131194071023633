<template>
  <div id="main">
    <!-- The main Html of the project. -->
    <div class="navbar-brand" style="top:2px; left:5px; position: absolute"><a href="https://stastudent.no" target="_blank"><img  width="100px" height="50px" src="https://stastudent.no/wp-content/uploads/2022/02/cropped-cropped-cropped-png-sta-logo-hvis-1-1.png" alt="logo"></a></div>
    <h1 class="main_header">Valgomat Studentvalget <br class="mobile-break"> UiA 2023</h1>
    <!-- Header -->

      <div class="buttons" v-if="page === numOfPages || page === -1 && currentRouteName !== 'newCand' && currentRouteName !== 'password'"> <!-- Buttons for navigation. Buttons could possibly be done smoother, but we wanted some over and some under, so went with this route. Might look a bit messy, but works very good. -->
        <button id="start" v-if="page === -1" v-on:click="OnStart" class="navigation">Start</button>
        <button v-if="page === numOfPages" v-on:click="OnPrevious" class="navigation">Forrige</button>
        <button v-if="page === numOfPages"  v-on:click="OnReset" class="navigation">Reset</button>
      </div>
        <div v-if="page !== -1 && currentRouteName !== 'newCand' && currentRouteName !== 'password' && isCont === false" key="isCont" id="continue">Velg et svar</div>
        <!-- Shows red text if no answer is chosen -->
        <router-view class="main-view"><Buttons></Buttons></router-view> <!-- Questions come here, with buttons in the slots.
         This displays whatever the router sends us to. -->
      <hr class="skillelinje" v-if="page > -1 && page !== numOfPages && currentRouteName !== 'newCand' && currentRouteName !== 'password'"> <!-- Estetic purposes -->
      <div class="buttons" v-if="page > -1 && currentRouteName !== 'newCand' && currentRouteName !== 'password'" > <!-- Buttons for navigation. Same as over. -->
          <button  v-if="page > -1" v-on:click="OnPrevious" class="navigation">Forrige</button>
          <button v-if="page > -1 && page < numOfPages" id="next=" v-on:click="OnNext" class="navigation">Neste</button>
          <button v-if="page === numOfPages"  v-on:click="OnReset" class="navigation">Reset</button>
      </div>

    <Footer></Footer>  <!-- Footer.-->

  </div>
</template>

<script>
  import {page, questions, sharedVars} from "./sharedVars";
  import router from "./router";
  import Buttons from "./components/Buttons";
  import Footer from "./components/Footer";
  import {calcOnClick} from "./comparison.js";
  const image = require("./assets/favicon.png");

  export default {
  name: "App",
  metaInfo(){
    return {
      title: 'Stavalgomaten 2022',
      meta: [
        {name: 'description', content: "Valgomat for studentvalget ved UiA 2022. Her kan du svare på enkle påstander," +
              " og finne ut hvilke av kandidatene ved ditt fakultet du er mest enig med i disse spørsmålene."},
      ],
      link:[
        {rel: 'icon', href: this.favicon}
      ]
    }

  },
  components:
  {
    Buttons,
    Footer
  },
  data(){
    return{
      isCont: true, //Bool for checking if the user can continue or needs to answer question
      numOfPages: sharedVars.numOfPages, //Check how many pages we have.
      favicon: image,
    }
  },
  methods:
  {
    OnNext() //When button "Neste" is clicked.
    {
      calcOnClick(page.get(), questions.get(page.get())); //Call a function from "comparison.js"
      if(questions.get(page.get()) != null){ //The question is answered, we can continue.
        this.isCont = true;
        if(questions.getFull().length-1 <= page.get())
        {
          const path = '/result'; //Define where the result page lies.
          if (this.$route.path !== path) //If we're at the end question, we go to result.
          {
            page.set(1);
            router.push({path: path});
          }
        }
        else //Else, we go to next question.
        {
          page.set(1);
          router.push({path: "/" + page.get()});

        }
      }
      else //The question is not answered. We warn the user to answer the question.
      {
        this.isCont = false;
      }
    },
    OnPrevious() //On "Forrige". Logics, logics.
    {
      if(this.isCont === false)
      {
        this.isCont = true;
      }
      let temp = page.get();
      if(temp > -1){
        page.set(-1);
        temp = page.get();
        if (temp == -1)
        {
          router.push({path: "/"})
        }
        else{
          router.push({path: "/" + temp})
        }

      }

    },
    OnStart() //When start is pressed, we go to Question 0.
    {
      if(this.$route.path == "/newcand"){
        page.set(0);
        router.push({path: "/newcand/0"})
      }
      else{
        page.set(0);
        router.push({path: "/0"});
        //console.log(page.get() + " " + questions.get(page.get()))
      }


    },
    OnReset() //We ask the user if he/she really wants to reset, as they can't view their results anymore.
    {
      let ask = confirm('Er du sikker? Resultatet vil bli mistet.');
      if(ask){
        this.$router.go(0);
      }
    },
  },
  computed:
  {
    page: function (){
      return page.get()
    },
    currentRouteName: function (){
      return this.$route.name;
    }
  },

}
</script>

<style>

@import './main.css'; /* We import all our css from an external file. Prefer to to it this way. */

</style>
